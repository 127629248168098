import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/services/AnalyticsService/types';
import { URLS } from './urls';
import { FormTypesEnum } from './enums';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountSecurity/';
export const screenDetailsPath = '/ReactMyAccountSecurity/Security/';
export const screenName = 'Security';
export const screenDetailsName = 'SecurityDetails';
const eventDetailVersion = '1.5.0';

type AnalyticsEventPropTypes = {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
) => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const SecurityScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const SecurityDetailsScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: screenDetailsPath,
  screenName: screenDetailsName,
  version: eventDetailVersion
};

export const ModuleDisplayedErrorSecurity = {
  action: ACTIONS.MODULE_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const ManageLoginDetailsHyperLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageLoginDetailsLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManageEmailHyperLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageEmailLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManageEmailControlButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageEmailButton',
  actionAuxParams: `type=${FormTypesEnum.Email}`,
  version: eventDetailVersion
};

export const ManagePhoneHyperLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManagePhoneLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ManagePhoneControlButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManagePhoneButton',
  actionAuxParams: `type=${FormTypesEnum.Phone}`,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const ChangePasswordHyperLinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePasswordLink',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const ChangePasswordControlButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePasswordButton',
  actionAuxParams: `type=${FormTypesEnum.Password}`,
  version: eventDetailVersion
} as AnalyticsEventPropTypes;

export const ManageAdvancedSecurityOptionsHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageAdvancedSecurityOptions',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const MobileTwoFactorAuthHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'MobileTwoFactorAuth',
  controlDetail: URLS.MobileTwoFactorAuth,
  version: eventDetailVersion
};

export const ManageActiveSessionsHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ManageActiveSessions',
  controlDetail: URLS.HpIdSecurity,
  version: eventDetailVersion
};

export const LoginDetailsError = (errorInfo: string) => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}/Security/`,
    screenName: 'LoginDetailsError',
    version: eventDetailVersion
  };
};
