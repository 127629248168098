import { Dispatch, SetStateAction } from 'react';
import { FeatureFlag, FeatureFlagLoadingState } from 'src/types/FeatureFlag';
import { FIELDS } from '@hpx-core-experiences/react-my-account-commons/dist/index';

export interface LoginDetails {
  email: string;
  emailVerified: string;
  phone: string;
  phoneVerified: string;
}

export interface FieldConfig {
  field: string;
  setter: (value: string) => void;
  flag: boolean;
  loading: boolean;
  propertySelector?: string;
}

/**
 * Filters the fields based on their associated feature flags and loading state.
 *
 * This function takes an array of FieldConfig objects and returns a new array
 * containing only the fields where the flag is set to true and none of the
 * fields are in a loading state.
 *
 * @param fields - An array of FieldConfig objects to be filtered.
 * @returns A filtered array of FieldConfig objects.
 */
export const filterFields = (fields: FieldConfig[]): FieldConfig[] => {
  return Array.isArray(fields)
    ? fields.filter((field) => field.flag && !field.loading)
    : [];
};

/**
 * Generates a list of fields to fetch based on the provided flags and sets the login details state.
 *
 * This function is used to determine which fields should be fetched and displayed based on the provided
 * feature flags. It also updates the login details state with the fetched data from HPID.
 *
 * @param setLoginDetails - Function to update the login details state.
 * @param flags - Feature flags to control which fields should be fetched.
 * @param flagsLoadingState - Loading state of the feature flags.
 * @returns An array of FieldConfig objects containing the configuration for each field to be fetched.
 */
const getFieldsToFetch = (
  setLoginDetails: Dispatch<SetStateAction<LoginDetails>>,
  flags: FeatureFlag,
  flagsLoadingState: FeatureFlagLoadingState
): FieldConfig[] => {
  const fields: FieldConfig[] = [
    {
      field: FIELDS.EMAIL,
      setter: (value: string) =>
        setLoginDetails((prev) => ({ ...prev, email: value })),
      propertySelector: 'value',
      flag: flags.isSecurityHpIdEmailRetrievalEnabled,
      loading: flagsLoadingState.isSecurityHpIdEmailLoading
    },
    {
      field: FIELDS.EMAIL,
      setter: (value: string) =>
        setLoginDetails((prev) => ({ ...prev, emailVerified: value })),
      propertySelector: 'verified',
      flag: flags.isSecurityHpIdEmailRetrievalEnabled,
      loading: flagsLoadingState.isSecurityHpIdEmailLoading
    },
    {
      field: FIELDS.PHONE,
      setter: (value: string) =>
        setLoginDetails((prev) => ({ ...prev, phone: value })),
      flag: flags.isSecurityHpIdPhoneRetrievalEnabled,
      loading: flagsLoadingState.isSecurityHpIdPhoneLoading
    },
    {
      field: FIELDS.PHONE,
      setter: (value: string) =>
        setLoginDetails((prev) => ({ ...prev, phoneVerified: value })),
      propertySelector: 'verified',
      flag: flags.isSecurityHpIdPhoneRetrievalEnabled,
      loading: flagsLoadingState.isSecurityHpIdPhoneLoading
    }
  ];

  return filterFields(fields);
};

export default getFieldsToFetch;
