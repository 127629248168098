export enum FeatureFlagsEnum {
  qualtricsSurvey = 'qualtrics-survey',
  securityHPIDEmail = 'security-hp-id-email',
  securityHPIDEmailRetrieval = 'security-hp-id-email-retrieval',
  securityHPIDPassword = 'security-hp-id-password',
  securityHPIDPhone = 'security-hp-id-phone',
  securityHPIDPhoneRetrieval = 'security-hp-id-phone-retrieval',
  securityListLoginDetails = 'security-list-login-details',
  originalFeedbackExperience = 'original-feedback-experience'
}

export enum FormTypesEnum {
  Email = 'email',
  Phone = 'phone',
  Password = 'password'
}
