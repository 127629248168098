import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { FeatureFlag, FeatureFlagLoadingState } from 'src/types/FeatureFlag';
import getFieldsToFetch from 'src/utils/getFieldsToFetch';

interface RetrieveHPIDFieldsProps {
  field?: string;
  setter?: (data: string) => void;
  propertySelector?: string;
}

interface RetrieveHPIDFields extends RetrieveHPIDFieldsProps {
  (
    field?: string,
    setter?: (data: string) => void,
    propertySelector?: string
  ): void;
}

/**
 * Custom hook to fetch and set login details based on feature flags.
 *
 * @param setLoginDetails - Function to update the login details state.
 * @param flags - Feature flags to control which fields should be fetched.
 * @param flagsLoadingState - Loading state of the feature flags.
 * @param isHPIDLoginDetailsRetrievalEnabled - Flag to determine if HPID login details retrieval is enabled.
 * @param retrieveHPIDFields - Function to retrieve HPID fields data.
 *
 * This hook is used in the `LoginDetails` component to fetch and set the user's login details retrieved from HPID.
 * The hook uses the `getFieldsToFetch` function to determine which fields should be fetched.
 * The `getFieldsToFetch` function takes into account the provided feature flags (`flags`) to decide which fields are necessary.
 */
const useHPIDLoginDetails = (
  setLoginDetails: Dispatch<SetStateAction<any>>,
  flags: FeatureFlag,
  flagsLoadingState: FeatureFlagLoadingState,
  isHPIDLoginDetailsRetrievalEnabled: boolean,
  retrieveHPIDFields: RetrieveHPIDFields
): void => {
  const [fieldsToFetch, setFieldsToFetch] = useState<RetrieveHPIDFieldsProps[]>(
    []
  );

  // Update fields to fetch whenever flags or their loading states change
  useEffect(() => {
    const fields = getFieldsToFetch(setLoginDetails, flags, flagsLoadingState);
    setFieldsToFetch(fields);
  }, [flags, flagsLoadingState, setLoginDetails]);

  // Fetch data based on the fields to fetch
  const fetchData = useCallback(() => {
    fieldsToFetch?.forEach(
      async ({ field, setter, propertySelector }: RetrieveHPIDFieldsProps) => {
        try {
          await retrieveHPIDFields(field, setter, propertySelector);
        } catch (error) {
          console.error(`Error fetching field ${field} from HPID:`, error);
        }
      }
    );
  }, [retrieveHPIDFields, fieldsToFetch]);

  // Trigger data fetch if login details retrieval is enabled
  useEffect(() => {
    if (isHPIDLoginDetailsRetrievalEnabled) {
      fetchData();
    }
  }, [fetchData, isHPIDLoginDetailsRetrievalEnabled]);
};

export default useHPIDLoginDetails;
